export const States = {
  partsSearchLocations: [],
  cameraCount: 0,
  plannerBoardType: 'week',
  isLogout: false,
  cards: [],
  lastTimeForCards: 0,
  vehiclesForCustom: {},
  vehicleMakeModels: {},
  vehicleScheduleNumbers: {},
  menu: {},
  filter: {
    card: '',
    estimate: '',
    filterForMine: true,
    invoice: '',
  },
  jobs: [],
  pcs: [],
  scannerApiKey: null,
  isCanUpdate: true,
  butterfly: {
    bodies: {},
    categories: {},
    customMatrix: {},
    matrix: {},
    names: {},
    matrixOrder: {},
  },
  userInfo: {},
  users: {},
  vendorInfo: {
    activeXeroAuth: false,
    iagAuth: {
      webserviceId: null,
      webservicePassword: null,
      repairerName: null,
      repairerCode: null,
      abn: null,
      active: false,
      status: null,
      lastError: null,
      statusChanged: null,
    },
    features: {},
  },
  businessTypes: [],
  dashboardSummary: {},
  customerVehicles: [],
  insurers: [],
  insurances: [],
  assessors: [],
  activityLog: {
    'I': 'Internal System',
    'E': 'External System',
    'U': 'User'
  },
  status: '',
  token: localStorage.getItem('token') || '',
  addressStates: [
    { key: 'ACT', value: 'Australian Capital Territory' },
    { key: 'QLD', value: 'Queensland' },
    { key: 'NT', value: 'Northern Territory' },
    { key: 'NSW', value: 'New South Wales' },
    { key: 'VIC', value: 'Victoria' },
    { key: 'TAS', value: 'Tasmania' },
    { key: 'SA', value: 'South Australia' },
    { key: 'WA', value: 'Western Australia' },
  ],
  addressCountries: [
    { key: 'AU', value: 'Australia' },
    { key: 'NZ', value: 'New Zealand' },
  ],
  vehicleTransmissions: [
    { key: 'Automatic', value: 'Automatic' },
    { key: 'Manual', value: 'Manual' },
  ],
  roles: [
    {
      id: 2,
      label: 'Shop Manager',
      value: 'ShopManager',
    },
    {
      id: 10,
      label: 'Production Manager',
      value: 'ProductionManager',
    },
    {
      id: 3,
      label: 'Estimator',
      value: 'Estimator'
    },
    {
      id: 20,
      label: 'PDR Technician',
      value: 'PDRTechnician'
    },
    {
      id: 4,
      label: 'Panel Technician',
      value: 'PanelTechnician'
    },
    {
      id: 6,
      label: 'Paint Technician',
      value: 'PaintTechnician'
    },
    {
      id: 5,
      label: 'Customer Service',
      value: 'CustomerService',
    },
    {
      id: 7,
      label: 'Stripper & Fitter',
      value: 'StripperFitter'
    },
    {
      id: 8,
      label: 'Detailer',
      value: 'Detailer'
    },
    {
      id: 200,
      label: 'Display Board',
      value: 'DisplayBoard'
    },
  ],
  updateData: {},

  fileIdsInCardEdit: [],
  filesInCardEdit: [],
  isActiveLookup: false,
  isActiveLookupAlwaysOn: false,
  insurerCardColor:{
    1: {
      name: 'orange',
      class: 'insurer-card-color-id-1',
      color: '#f85d09',
      background: '#f9e3d4',
    },
    2: {
      name: 'blue',
      class: 'insurer-card-color-id-2',
      color: '#051583',
      background: '#cbcee4',
    },
    3: {
      name: 'lightBlue',
      class: 'insurer-card-color-id-3',
      color: '#35bad7',
      background: '#d5eff5',
    },
    4: {
      name: 'yellow',
      class: 'insurer-card-color-id-4',
      color: '#f8d040',
      background: '#fcf4d7',
    },
    5: {
      name: 'green',
      class: 'insurer-card-color-id-5',
      color: '#88cd1b',
      background: '#e5f3cf',
    },
    6: {
      name: 'darkGreen',
      class: 'insurer-card-color-id-6',
      color: '#1b5712',
      background: '#cfdbce',
    },
    7: {
      name: 'purple',
      class: 'insurer-card-color-id-7',
      color: '#886ecc',
      background: '#e5e0f3',
    },
    8: {
      name: 'pink',
      class: 'insurer-card-color-id-8',
      color: '#e85076',
      background: '#f8dae2',
    },
  }
}
